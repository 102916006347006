<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-4">
      <app-bar></app-bar>
      <v-row justify="space-around" class="mt-4">
        <v-card width="600">
          <v-card-title> New Payment </v-card-title>
          <v-card-text>
            <v-text-field
              required
              :rules="[(v) => !!v || 'Card no is required']"
              v-model="payment.card_no"
              label="Card No"
              filled
              dense
            ></v-text-field>

            <v-text-field
              required
              :rules="[(v) => !!v || 'Name is required']"
              v-model="payment.name"
              label="Name on Card"
              filled
              dense
            ></v-text-field>
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      required
                      :rules="[(v) => !!v || 'Date is required']"
                      filled
                      dense
                      v-model="payment.exp_date"
                      label="Expiration Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="payment.exp_date"
                    type="month"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(payment.exp_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  required
                  :rules="[(v) => !!v || 'CVV is required']"
                  v-model="payment.cvv"
                  label="Security Code"
                  :counter="3"
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              required
              :rules="[(v) => !!v || 'Amount is required']"
              v-model="payment.amount"
              prepend-inner-icon="mdi-currency-usd"
              label="Amount"
              filled
              dense
            ></v-text-field>

            <v-textarea
              v-model="payment.description"
              label="Description"
              filled
              dense
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#6c4a9e" dark @click="checkValidation()"> Pay </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-form>

    <!-- Dialog Box -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      persistent
      v-model="dialog"
    >
      <v-card v-if="payment_obj">
        <v-toolbar :color="payment_obj.type == '1' ? 'success' : 'error'" dark
          >{{ payment_obj.type == "1" ? "Success" : "Error" }}
        </v-toolbar>
        <v-card-text class="mt-2">
          <p v-for="(item, i) in payment_obj" :key="`i${i}`">
            <span class="font-weight-bold">{{ capitalizeFirstLetter(i) }}</span>
            : {{ item }}
          </p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeDialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirmation -->
    <div class="text-center">
      <v-bottom-sheet v-model="confimation_sheet" inset>
        <v-sheet class="text-center" height="150px">
          <div class="mb-3 pt-6 headline">
            Confirm payment of ${{ payment.amount }}
          </div>
          <v-btn
            class="mt-2"
            text
            color="error"
            @click="confimation_sheet = false"
          >
            No
          </v-btn>
          <v-btn class="mt-2" text color="success" @click="pay()"> Yes </v-btn>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppBar from "@/components/AppBar";
export default {
  data() {
    return {
      confimation_sheet: false,
      valid: true,
      dialog: false,
      payment_obj: null,
      payment: {
        card_no: "",
        name: "",
        exp_date: "",
        cvv: "",
        amount: "",
        description: "",
        form_type: "new_transaction",
      },
      date: new Date().toISOString().substr(0, 7),
      menu: false,
    };
  },
  components: {
    AppBar,
  },
  methods: {
    ...mapActions(["logoutUser", "createTransaction"]),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    logout() {
      this.logoutUser();
      this.$router.push({ name: "Login" });
    },
    checkValidation() {
      if (this.$refs.form.validate()) this.confimation_sheet = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$router.push({ name: "Home" });
    },
    pay() {
      this.confimation_sheet = false;

      this.$vloading.show();
      this.createTransaction({
        token: this.userToken,
        card_detail: this.payment,
      })
        .then((res) => {
          this.dialog = true;
          this.payment_obj = res;
        })
        .catch((err) => {
          this.dialog = true;
          this.payment_obj = res;
        })
        .finally(() => {
          this.$vloading.hide();
        });
    },
  },

  computed: {
    ...mapGetters(["userName", "userToken"]),
  },
};
</script>
